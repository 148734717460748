<template>
  <div id="DefaultPublicNewsletters">
    <card style="grid-column: 2 / span 2">
      <div class="contact-newsletter-kpi">
        <qs-kpi qlik-id="pyTYQE" style="grid-column: 1 / 5; font-size: 28px" />
        <qs-kpi
          qlik-id="QvJCbYt"
          style="grid-column: 5 / -1; font-size: 13px"
        />
      </div>
    </card>
    <GoToDataModificationButton
      @click.native="$mixpanel.track('Click add contact button')"
      title="Ajouter des contacts"
    />

    <QsCard
      :qlik-ids="['JsBVpXe']"
      style="grid-column: 1 / span 4"
      card-style="compact"
    />

    <QsCard :qlik-ids="['xLaJCj']" style="grid-column: 1 / span 2" />
    <QsCard :qlik-ids="['mxpjAEt']" style="grid-column: 3 / span 2" />
  </div>
</template>

<script>
import Card from "@/components/Card";
import QsKpi from "@/components/Qlik/QsKpi";
import QsCard from "@/components/Qlik/QsCard";
import GoToDataModificationButton from "@/components/GoToDataModificationButton.vue";

export default {
  components: { Card, QsKpi, QsCard, GoToDataModificationButton }
};
</script>

<style scoped>
#DefaultPublicNewsletters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 150px 45px 320px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}

.contact-newsletter-kpi {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: 100%;
  align-items: center;
}
</style>
